export const formatCurrency = (value: number) => {
  return value
    ? value
        .toLocaleString("vi-VN", { style: "currency", currency: "VND" })
        .replace("VND", "đ")
    : "0 đ";
};

export const discountPrice = (price: number, discount: number) => {
  return price - (price * discount) / 100;
};

export const getBankTransferInfomation = (depositCode: string) => [
  {
    title: "Số Tài Khoản",
    value: "4235887",
  },
  {
    title: "Tên Tài Khoản",
    value: "NGUYEN HUY LAN",
  },
  {
    title: "Ngân Hàng",
    value: "ACB",
  },
  {
    title: "Nội dung chuyển tiền",
    value: depositCode,
  },
];
